import Request from "./request";
// get方法
export function get(val) {
  return Request({
    method: "get",
    url: val.apiUrl,
    params: val.params,
  });
}
// post方法
export function post(val) {
  return Request({
    method: "post",
    url: val.apiUrl,
    data: val.params,
  });
}
// put方法
export function put(val) {
  return Request({
    method: "put",
    url: val.apiUrl,
    data: val.params,
  });
}
// 账号密码登录
export const login = (data) =>
  post({ apiUrl: "login/loginByPassword", params: data });
// 获取验证码
export const sendSms = (data) =>
  post({ apiUrl: "login/sendSms", params: data });
// 获取二维码
export const getWxQrCode = (data) =>
  get({ apiUrl: "login/getWxQrCode", params: data });
// 获取二维码
export const getUserLoginStatus = (data) =>
  get({ apiUrl: "login/getStatusByTicket", params: data });
// 验证码登录
export const loginByCode = (data) =>
  post({ apiUrl: "login/loginByCode", params: data });
// 修改密码
export const resetPassword = (data) =>
  post({ apiUrl: "user/setPassword", params: data });
// 获取列表
export const getUserProjectList = (data) =>
  post({ apiUrl: "project/getUserProjectList", params: data });
// 预览
export const getSignedUrl = (data) =>
  post({ apiUrl: "project/getSignedUrl", params: data });



// 添加活动 activity/add
export const activityAdd = (data) =>
  post({ apiUrl: "activity/add", params: data });
// 获取活动列表 activity/getList
export const activityGetList = (data) =>
  post({ apiUrl: "activity/getList", params: data });
// 编辑 activity/edit
export const activityEdit = (data) =>
  post({ apiUrl: "activity/edit", params: data });
// 订单列表
export const getOrderList = (data) =>
  post({ apiUrl: "order/getList", params: data });
// 设为已付款 order/setPayment
export const setPayment = (data) =>
  post({ apiUrl: "order/setPayment", params: data });
// 报名记录 
export const actUserGetList = (data) =>
  post({ apiUrl: "actUser/getList", params: data });
// 报名审核 
export const actUserReview = (data) =>
  post({ apiUrl: "actUser/review", params: data });
// actUser/setDefSort
// 报名排序 
export const actUserSetDefSort = (data) =>
  post({ apiUrl: "actUser/setDefSort", params: data });
// 报名取消
export const actUserCancel = (data) =>
  post({ apiUrl: "actUser/cancel", params: data });
// 恢复报名
export const actUserRestore = (data) =>
  post({ apiUrl: "actUser/restore", params: data });
// 获取活动详情 
export const activityGetDetail = (data) =>
  post({ apiUrl: "activity/getDetail", params: data });
// 编辑
export const actUserEdit = (data) =>
  post({ apiUrl: "actUser/edit", params: data });
// 房间分配 actUser/getList 
export const assignsRoom = (data) =>
  post({ apiUrl: "actUser/assignsRoom", params: data });
// 活动概况 
export const getOverview = (data) =>
  post({ apiUrl: "activity/getOverview", params: data })
// 列表title
export const getStatistic = (data) =>
  post({ apiUrl: "actUser/getStatistic", params: data });
// 房间列表 
export const getRoomOverview = (data) =>
  post({ apiUrl: "activity/getRoomOverview", params: data });
// 获取排座信息 actUser/getSeat 
export const actUserGetSeat = (data) =>
  post({ apiUrl: "actUser/getSeat", params: data });
// 保存座位信息 
export const userSaveSeat = (data) =>
  post({ apiUrl: "actUser/saveSeat", params: data });
// 列数 
export const updateColumnNum = (data) =>
  post({ apiUrl: "activity/updateColumnNum", params: data });
// 活动签到
export const setActivityStatus = (data) =>
  post({ apiUrl: "actUser/setActivityStatus", params: data });
// 酒店入住
export const setHotelStatus = (data) =>
  post({ apiUrl: "actUser/setHotelStatus", params: data });
// 会议签到
export const setMeetingStatus = (data) =>
  post({ apiUrl: "actUser/setMeetingStatus", params: data });
//  晚宴参加
export const dinnerSignUp = (data) =>
  post({ apiUrl: "actUser/dinnerSignUp", params: data });
// 晚宴
export const setDinnerStatus = (data) =>
  post({ apiUrl: "actUser/setDinnerStatus", params: data });
// 导出文件
export const seatExcel = (data) =>
  post({ apiUrl: "activity/seatExcel", params: data });
// 获取房间
export const getNoRoomList = (data) =>
  post({ apiUrl: "actUser/getNoRoomList", params: data });
// 删除房间 
export const deleteRoom = (data) =>
  post({ apiUrl: "actUser/deleteRoom", params: data });
// 取消订单
export const orderCancel = (data) =>
  post({ apiUrl: "order/cancel", params: data });
// 发票   
export const invoiceGetList = (data) =>
  post({ apiUrl: "invoice/getList", params: data });
// 发票编辑
export const invoiceUpdate = (data) =>
  post({ apiUrl: "invoice/update", params: data });
// 发票删除
export const invoiceDelete = (data) =>
  post({ apiUrl: "invoice/delete", params: data });
// 会议删除
export const activityDelete = (data) =>
  post({ apiUrl: `/activity/delete`, params: data });
// 发票导出
export const exportExcel = (data) =>
  post({ apiUrl: `/invoice/exportExcel`, params: data });
// 发票导出
export const exportExcels = (data) =>
  post({ apiUrl: `actUser/exportExcel`, params: data });
/**
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
*/
// apiUrl: `login/write?loginName=${data.loginName}&password=${data.password}`,
// login 登录按钮- 账号密码登录
export const loginWrite = (data) =>
  post({ apiUrl: "login/write", params: data });
//  主页菜单
export const indexMenu = (data) => get({ apiUrl: "index/menu" });
//用户管理
export const getUserList = (data) =>
  post({ apiUrl: "user/getUserList", params: data });
//用户管理:评估记录
export const getValuationRecord = (data) =>
  post({ apiUrl: "user/getValuationRecord", params: data });
//用户管理:评估记录
export const getAccountRecord = (data) =>
  post({ apiUrl: "user/getAccountRecord", params: data });
//用户管理:充值
export const recharge = (data) =>
  post({ apiUrl: "user/recharge", params: data });
//用户管理:年检
export const inspect = (data) => post({ apiUrl: "user/inspect", params: data });
//身份实名认证列表
export const idCardCardList = (data) =>
  post({ apiUrl: "idCard/cardList", params: data });
// 学历认证页面
export const educationList = (data) =>
  post({ apiUrl: "education/educationList", params: data });
// 学历审核
export const educationCheck = (data) =>
  post({ apiUrl: "education/educationCheck", params: data });
//证书管理
export const certificateList = (data) =>
  post({ apiUrl: "archives/certificateList", params: data });
//证书管理-导出
export const invoiceCertExcel = (data) =>
  post({ apiUrl: "/invoice/certExcel", params: data });
//考试管理
export const examList = (data) =>
  post({ apiUrl: "/archives/examList", params: data });
//考试管理详情
export const examDetails = (data) =>
  post({ apiUrl: "/archives/examDetails", params: data });
//岗位技能证书打印
export const assCertificate = (data) =>
  post({ apiUrl: "/archives/assCertificate", params: data });
//评估师技能证书打印
export const evaCertificate = (data) =>
  post({ apiUrl: "/archives/evaCertificate", params: data });
//学员管理
export const studentList = (data) =>
  post({ apiUrl: "archives/studentList", params: data });
//成绩登记
export const achievement = (data) =>
  post({ apiUrl: "archives/achievement", params: data });
//制证
export const make_certificates = (data) =>
  post({ apiUrl: "/archives/make_certificates", params: data });
//培训班报名
export const trainTrainList = (data) =>
  post({ apiUrl: "/train/trainList", params: data });
//导出信息
export const invoicExcel = (data) =>
  post({ apiUrl: "/invoice/invoicExcel", params: data, responseType: "blob" });
//激活
export const activation = (data) =>
  post({ apiUrl: "/archives/activation", params: data });
//撤销
export const revokeCert = (data) =>
  post({ apiUrl: "/archives/revokeCert", params: data });
//新建培训班
export const trainAddTrain = (data) =>
  post({ apiUrl: "/train/addTrain", params: data });
//培训班详情
export const trainSignDetails = (data) =>
  post({ apiUrl: "/train/signDetails", params: data });
//培训班详情
export const createExcel = (data) =>
  post({ apiUrl: "/train/createExcel", params: data });
//培训班签到
export const trainSign = (data) =>
  post({ apiUrl: "/train/sign", params: data });
//培训班转班
export const studentTransfer = (data) =>
  post({ apiUrl: "/train/studentTransfer", params: data });
//学员证件
export const getStudentCard = (data) =>
  post({ apiUrl: "/train/getStudentCard", params: data });
//缴费报名列表
export const trainUserList = (data) =>
  post({ apiUrl: "/train/trainUserList", params: data });
//对公缴费报名
export const signUpSuccess = (data) =>
  post({ apiUrl: "/train/signUpSuccess", params: data });
//取消报名
export const signUpCancel = (data) =>
  post({ apiUrl: "/train/signUpCancel", params: data });
//发票信息
export const invoiceDetails = (data) =>
  post({ apiUrl: "/invoice/invoiceDetails", params: data });
//开票信息
export const outInvoicing = (data) =>
  post({ apiUrl: "/invoice/invoicing", params: data });
//评估订单
export const evaOrderList = (data) =>
  post({ apiUrl: "/eva/evaOrderList", params: data });
//评估订单详情
export const evaOrderDetails = (data) =>
  post({ apiUrl: "/eva/evaOrderDetails", params: data });
//评估任务列表
export const evaTaskList = (data) =>
  post({ apiUrl: "/eva/evaTaskList", params: data });
//评估任务列表详情
export const evaTaskListDetails = (data) =>
  post({ apiUrl: "/eva/evaTaskDetails", params: data });
//复核列表
export const evaReviewList = (data) =>
  post({ apiUrl: "/eva/evaReviewList", params: data });
//复核列表详情
export const evaReviewListDetails = (data) =>
  post({ apiUrl: "/eva/evaReviewDetails", params: data });
//课程列表
export const courseList = (data) =>
  post({ apiUrl: "/course/courseList", params: data });
//课程列表
export const adpCourse = (data) =>
  post({ apiUrl: "/course/adpCourse", params: data });
//课程详情
export const course = (data) =>
  post({ apiUrl: "/course/course", params: data });
//课程列表删除
export const delCourse = (data) =>
  post({ apiUrl: "/course/delCourse", params: data });
//课程列表新建教材合集
export const coursewareList = (data) =>
  post({ apiUrl: "/course/coursewareList", params: data });
//教材列表
export const wareList = (data) =>
  post({ apiUrl: "/ware/wareList", params: data });
//教材列表
export const adpCourseware = (data) =>
  post({ apiUrl: "/ware/adpCourseware", params: data });
//教材列表删除
export const delCourseware = (data) =>
  post({ apiUrl: "/ware/delCourseware", params: data });
//教材详情
export const courseware = (data) =>
  post({ apiUrl: "/ware/courseware", params: data });
//岗位列表
export const assessorList = (data) =>
  post({ apiUrl: "/assessor/assessorList", params: data });
//岗位详情
export const assessor = (data) =>
  post({ apiUrl: "/assessor/assessor", params: data });
//岗位课程列表
export const levelCourseList = (data) =>
  post({ apiUrl: "/assessor/levelCourseList", params: data });
//新建岗位
export const adpAssessor = (data) =>
  post({ apiUrl: "/assessor/adpAssessor", params: data });
//新建岗位
export const delAssessor = (data) =>
  post({ apiUrl: "/assessor/delAssessor", params: data });
// 试卷管理-列表
export const paperPaperList = (data) =>
  post({ apiUrl: "/paper/paperList", params: data });
// 试卷管理-删除
export const paperDelPaper = (data) =>
  post({ apiUrl: "/paper/delPaper", params: data });
// 试卷管理-编辑-查看 获取
export const paperPaper = (data) =>
  post({ apiUrl: "/paper/paper", params: data });
// 试卷管理-编辑-新建 提交
export const paperAdpPaper = (data) =>
  post({ apiUrl: "/paper/adpPaper", params: data });
// 试卷-列表-查看
export const assessorAssessorList = (data) =>
  post({ apiUrl: "/assessor/assessorList", params: data });
// 习题管理-列表
export const questionQuestionList = (data) =>
  post({ apiUrl: "/question/questionList", params: data });
// 习题管理-列表-查看
export const questionQuestion = (data) =>
  post({ apiUrl: "/question/question", params: data });
// 习题管理-新建-添加
export const questionAdpQuestion = (data) =>
  post({ apiUrl: "/question/adpQuestion", params: data });
// 习题管理-列表-删除
export const questionDelQuestion = (data) =>
  post({ apiUrl: "/question/delQuestion", params: data });
// 身份管理-身份类型列表
export const identityGetList = (data) => get({ apiUrl: "/identity/getList" });
// 身份管理-身份类型状态
export const updateState = (data) =>
  post({ apiUrl: `identity/updateState`, params: data });
// 身份管理-身份类型基本信息
export const getBaseInfo = (data) =>
  get({ apiUrl: `/identity/getBaseInfo`, params: data });
// 身份管理-身份类型基本信息编辑
export const updateBaseInfo = (data) =>
  post({ apiUrl: `/identity/updateBaseInfo`, params: data });
// 身份管理-身份类型权益信息编辑
export const updateEquity = (data) =>
  post({ apiUrl: `/identity/updateEquity`, params: data });
// 身份管理-身份类型年检信息编辑
export const updateCondition = (data) =>
  post({ apiUrl: `/identity/updateCondition`, params: data });
// 身份管理-身份类型权益信息
export const getEquity = (data) =>
  get({ apiUrl: `/identity/getEquity`, params: data });
// 身份管理-身份类型年审要求
export const getCondition = (data) =>
  get({ apiUrl: `/identity/getCondition`, params: data });
// 身份管理-年检记录
export const getInspectRecord = (data) =>
  get({ apiUrl: `/identity/getInspectRecord`, params: data });
// 身份管理-年检协议获取
export const getAgreement = (data) =>
  get({ apiUrl: `/agreement/getAgreement`, params: data });
// 身份管理-设置年检协议
export const updateAgreement = (data) =>
  post({ apiUrl: `/agreement/updateAgreement`, params: data });
// 身份管理-金额设置
export const getAmountConfig = (data) =>
  get({ apiUrl: `/recharge/getAmountConfig` });
// 身份管理-金额编辑
export const updateAmount = (data) =>
  post({ apiUrl: `/recharge/updateAmount`, params: data });
// 充值管理-充值设置
export const getCustomConfig = (data) =>
  get({ apiUrl: `/recharge/getCustomConfig` });
// 充值管理-充值设置编辑
export const updateCustom = (data) =>
  post({ apiUrl: `/recharge/updateCustom`, params: data });
// 充值管理-余额变动设置编辑
export const updateChangeItem = (data) =>
  post({ apiUrl: `/recharge/updateChangeItem`, params: data });
// 充值管理-余额变动设置
export const getChangeItemConfig = (data) =>
  get({ apiUrl: `/recharge/getChangeItemConfig` });
// 财务管理-充值记录
export const getRechargeRecord = (data) =>
  post({ apiUrl: `/recharge/getRechargeRecord`, params: data });
// 财务管理-充值记录
export const deleteRechargeRecord = (data) =>
  post({ apiUrl: `/recharge/deleteRechargeRecord`, params: data });
// 其他-公告列表
export const noticeGetList = (data) =>
  post({ apiUrl: `/notice/getList`, params: data });
// 其他-新建公告
export const addNotice = (data) =>
  post({ apiUrl: `/notice/add`, params: data });
// 其他-编辑公告
export const updateNotice = (data) =>
  post({ apiUrl: `/notice/update`, params: data });
// 其他-公告删除
export const deleteNotice = (data) =>
  post({ apiUrl: `/notice/delete`, params: data });
// 其他-公告详情
export const noticeDetail = (data) =>
  post({ apiUrl: `/notice/getDetail`, params: data });
// 其他-公告是否开启
export const getIndexConfig = (data) =>
  get({ apiUrl: `/notice/getIndexConfig` });
// 其他-公告状态编辑
export const updateIndexConfig = (data) =>
  post({ apiUrl: `/notice/updateIndexConfig`, params: data });
// 其他-消息列表
export const getMessageList = (data) =>
  post({ apiUrl: `/msgManage/getList`, params: data });
// 其他-消息列表
export const updateMessageState = (data) =>
  post({ apiUrl: `/msgManage/updateState`, params: data });
// 评估管理-车型类别列表
export const getVehicleCat = (data) =>
  post({ apiUrl: `/evaConfig/getVehicleCat`, params: data });
// 评估管理-新建车辆类别
export const addVehicleCat = (data) =>
  post({ apiUrl: `/evaConfig/addVehicleCat`, params: data });
// 评估管理-编辑车辆类别
export const updateVehicleCat = (data) =>
  post({ apiUrl: `/evaConfig/updateVehicleCat`, params: data });
// 评估管理-删除车辆类别
export const deleteVehicleCat = (data) =>
  post({ apiUrl: `/evaConfig/deleteVehicleCat`, params: data });
// 评估管理-车辆类别管理 车型参数and随车资料获取
export const getCatParamRel = (data) =>
  post({ apiUrl: `/evaConfig/getCatParamRel`, params: data });
// 评估管理-车辆类别管理 车型参数and随车资料更新
export const updateCatParamRel = (data) =>
  post({ apiUrl: `/evaConfig/updateCatParamRel`, params: data });
// 评估管理-车辆类别管理 图片管理获取
export const getCatImageRel = (data) =>
  post({ apiUrl: `/evaConfig/getCatImageRel`, params: data });
// 评估管理-车辆类别管理 图片管理更新
export const updateCatImageRel = (data) =>
  post({ apiUrl: `/evaConfig/updateCatImageRel`, params: data });
// 评估管理-车辆类别管理 检查内容获取
export const getCatInspectRel = (data) =>
  post({ apiUrl: `/evaConfig/getCatInspectRel`, params: data });
// 评估管理-车辆类别管理 检查内容更新
export const updateCatInspectRel = (data) =>
  post({ apiUrl: `/evaConfig/updateCatInspectRel`, params: data });
// 评估管理-参数分组列表
export const getParamGroup = (data) =>
  post({ apiUrl: `/evaConfig/getParamGroup`, params: data });
// 评估管理-新增参数分组
export const addParamGroup = (data) =>
  post({ apiUrl: `/evaConfig/addParamGroup`, params: data });
// 评估管理-编辑参数分组
export const updateParamGroup = (data) =>
  post({ apiUrl: `/evaConfig/updateParamGroup`, params: data });
// 评估管理-删除参数分组
export const deleteParamGroup = (data) =>
  post({ apiUrl: `/evaConfig/deleteParamGroup`, params: data });
// 评估管理-参数值获取
export const getParamTypes = (data) =>
  get({ apiUrl: `/evaConfig/getParamTypes` });
// 评估管理-新增参数配置
export const addParam = (data) =>
  post({ apiUrl: `/evaConfig/addParam`, params: data });
// 评估管理-删除参数配置
export const deleteParam = (data) =>
  post({ apiUrl: `/evaConfig/deleteParam`, params: data });
// 评估管理-获取参数配置
export const getParamDetail = (data) =>
  post({ apiUrl: `/evaConfig/getParamDetail`, params: data });
// 评估管理-参数编辑
export const updateParamDetail = (data) =>
  post({ apiUrl: `/evaConfig/updateParamDetail`, params: data });
// 评估管理-新增相签
export const addImgLabel = (data) =>
  post({ apiUrl: `/evaConfig/addImgLabel`, params: data });
// 评估管理-编辑相签
export const updateImgLabel = (data) =>
  post({ apiUrl: `/evaConfig/updateImgLabel`, params: data });
// 评估管理-获取相签
export const getImgLabels = (data) =>
  post({ apiUrl: `/evaConfig/getImgLabels`, params: data });
// 评估管理-删除相签
export const deleteImgLabel = (data) =>
  post({ apiUrl: `/evaConfig/deleteImgLabel`, params: data });
// 评估管理-获取参数配置列表
export const getParamList = (data) =>
  post({ apiUrl: `/evaConfig/getParamList`, params: data });
// 评估管理-新增检查项目
export const addInspectItem = (data) =>
  post({ apiUrl: `/evaConfig/addInspectItem`, params: data });
// 评估管理-编辑检查项目
export const updateInspectItem = (data) =>
  post({ apiUrl: `/evaConfig/updateInspectItem`, params: data });
// 评估管理-删除检查项目
export const deleteInspectItem = (data) =>
  post({ apiUrl: `/evaConfig/deleteInspectItem`, params: data });
// 评估管理-检查项目列表
export const getInspectItems = (data) =>
  post({ apiUrl: `/evaConfig/getInspectItems`, params: data });
// 评估管理-新增检查内容
export const addInspectDetail = (data) =>
  post({ apiUrl: `/evaConfig/addInspectDetail`, params: data });
// 评估管理-编辑检查内容
export const updateInspectDetail = (data) =>
  post({ apiUrl: `/evaConfig/updateInspectDetail`, params: data });
// 评估管理-获取检查内容
export const getInspectDetails = (data) =>
  post({ apiUrl: `/evaConfig/getInspectDetails`, params: data });
// 评估管理-删除检查内容
export const deleteInspectDetail = (data) =>
  post({ apiUrl: `/evaConfig/deleteInspectDetail`, params: data });
// 评估管理-保存评价基准
export const saveBenchmarks = (data) =>
  post({ apiUrl: `/evaConfig/saveBenchmarks`, params: data });
// 评估管理-获取评价基准
export const getBenchmarks = (data) =>
  post({ apiUrl: `/evaConfig/getBenchmarks`, params: data });
// 评估管理-获取鉴定规则
export const getEvaRule = (data) =>
  post({ apiUrl: `/evaConfig/getEvaRule`, params: data });
// 评估管理-获取车型类别检查项目
export const getCatInspectItems = (data) =>
  post({ apiUrl: `/evaConfig/getCatInspectItems`, params: data });
// 评估管理-提交车型类别检查项目
export const saveCatInspectItems = (data) =>
  post({ apiUrl: `/evaConfig/saveCatInspectItems`, params: data });
// 评估管理-提交车型类别规则设定
export const saveEvaRule = (data) =>
  post({ apiUrl: `/evaConfig/saveEvaRule`, params: data });
// 评估管理-查看报告
export const getTaskDetailByNumber = (data) =>
  post({ apiUrl: `/eva/getTaskDetailByNumber`, params: data });
// 属性管理 新建属性
export const sysDictDataSave = (data) =>
  post({ apiUrl: `/sysDictData/save`, params: data });
export const sysDictDataGetList = (data) =>
  post({ apiUrl: `/sysDictData/getList`, params: data });
export const sysDictDataDelete = (data) =>
  post({ apiUrl: `/sysDictData/delete`, params: data });

