/*
    网络请求
*/
import { Message } from "element-ui";
import axios from "axios";
import store from "../store";
// post请求使用
import qs from "query-string";
const request = axios.create({
  // baseURL: "http://192.168.3.90:8080/", //线上接口
  baseURL: "http://screen.chinaucv.com/web/", //线上接口
  timeout: 60000,
});
request.interceptors.request.use(
  (config) => {
    sessionStorage.getItem('token')
    // 获取token 赋值
    const token = sessionStorage.getItem('token')|| "";
    config.headers.token = token;
    // config.headers['Content-Type'] = "application/json"
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
request.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const { response } = err;
    if (response) {
      switch (response.status) {
        case 400:
          console.log(response.data);
          break;
        case 401:
          console.log(response.data);
          break;
        case 403:
          console.log(response.data);
          break;
        case 404:
          console.log(response.data);
          break;
        case 500:
          Message.error("服务器错误请稍后再试");
          break;
        default:
          console.log(response.data);
          break;
      }
    } else {
      Message.error("网络连接中断了！！！");
    }
  }
);
let Request = (params) => {
  return new Promise((resolve, reject) => {
    request({
      ...params,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export default Request;
